import { App, inject } from 'vue';
import axios, { AxiosInstance } from 'axios';

export const httpSymbol = Symbol('http');

export const http = (Vue: App) => {
  const httpInstance = axios.create({
    baseURL: process.env.VUE_APP_API,
  });
  Vue.config.globalProperties.$http = httpInstance; // eslint-disable-line no-param-reassign
  Vue.provide(httpSymbol, httpInstance);
};

export function useHttp() {
  const httpInstance = inject<AxiosInstance>(httpSymbol);
  if (!httpInstance) throw new Error('No http provided!');

  return httpInstance;
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $http: AxiosInstance;
  }
}
