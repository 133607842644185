import { App, inject, reactive } from 'vue';
import { LocaleOptions } from '@/config';
import { Dictionary, pl, en, uk } from '@/locales';

export interface Translator {
  locale: LocaleOptions;
  locales: {
    [key in LocaleOptions]: Dictionary;
  };
  tr (): Dictionary;
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $i: Translator;
  }
}

const translatorLibrary: Translator = {
  locale: process.env.VUE_APP_LOCALE || LocaleOptions.PL,
  locales: {
    pl,
    en,
    uk,
  },
  tr() {
    return this.locales[this.locale];
  },
};

export const translatorSymbol = Symbol('translator');

export const translator = (Vue: App) => {
  const i = reactive(translatorLibrary);
  Vue.config.globalProperties.$i = i; // eslint-disable-line no-param-reassign
  Vue.provide(translatorSymbol, i);
};

export const useTranslator = () => {
  const translatorInstance = inject<Translator>(translatorSymbol);
  if (!translatorInstance) throw new Error('No translator provided!');

  return translatorInstance;
};
