import { createStore } from 'vuex';

import user from './modules/user';
import loader from './modules/loader';
import global from './modules/global';

const storeOptions = {
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    user,
    loader,
    global,
  },
};

const store = createStore(storeOptions);

export default store;
