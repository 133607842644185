import { Dictionary } from "./models";

export const uk: Dictionary = {
  about: {
    title:'Хто ми',
    content: 'Ми лідер на ринку медичної інформації в Польщі. Протягом кількох років ми надаємо лікарям, фармацевтам та пацієнтам вичерпні дані про всі лікарські засоби, доступні на польському ринку. Ми також тримаємо вас в курсі останніх подій у сфері охорони здоров’я або цікавих рішень, які використовуються в медицині. У нашій пропозиції також є сучасні продукти у сфері нових технологій, такі як медичні мобільні додатки або програма для ведення медичної клініки.',
  },
  benefits: {
    benefits: {
      prescription: 'Продовжте термін дії е-рецепта',
      makeAppointment: 'Здійсніть е-візит приватно або на НФЗ',
      laboratoryTests: 'Перевірте результати своїх лабораторних досліджень',
      medicalHistory: 'Перегляд свою медичну історію',
    },
    makeAppointment: 'Призначити візит',
  },
  footer: {
    nav: {
      doctors: {
        title: 'для лікарів',
        drw: 'Безкоштовна програма для ведення медичного закладу',
      },
      etc: 'дивитися також',
    },
  },
  header: {
    signin: 'Увійти',
    signinMenu: {
      patient: 'Пацієнт',
      clinic: 'Клініка',
    },
    nav: {
      howItWorks: 'як це працює',
      specialists: 'спеціалісти',
      mobileApp: 'мобільний додаток',
      aboutUs: 'про нас',
      clinicZone: 'Зона клініки',
    },
    makeAppointment: 'Призначити візит',
  },
  howItWorks: {
    title: 'Як це працює',
    description: 'Mydr — це сервіс, який об\'єднує пошукову систему для лікарів з повним доступом до ваших медичних даних.\nОбліковий запис пацієнта в Інтернеті, завдяки якому ви маєте доступ до всіх своїх медичних даних.',
    recap: 'Запишіться на візит, отримайте е-рецепт або е-лікарняний, не виходячи з дому.',
  },
  mobile: {
    title: [
      {
        name: 'bold',
        data: 'Завантажте програму',
      },
      'і увійдіть до свого здоров\'я',
    ],
    android: [
      {
        name: 'bold',
        data: 'безкоштовний',
      },
      'додаток\nля пристроїв із системою Android',
    ],
    ios: [
      {
        name: 'bold',
        data: 'безкоштовний',
      },
      'додаток\nля пристроїв із системою iOS',
    ],
  },
  offer: {
    title: 'Ви в хороших руках',
    safety: 'Гарантія безпеки\n ваших даних',
    cancelation: 'Можливість безкоштовно скасувати візит або змінити його термін',
    availability: 'Доступ до послуги\n 24 години на добу, 7 днів на тиждень',
  },
  specialists: {
    title: [
      'Найкращі спеціалісти',
      {
        name: 'bold',
        data: 'до ваших послуг',
      },
    ],
    signup: 'Зареєструватись',
    doctors: 'лікарі різних\n спеціалізацій',
    specialties: 'спеціалізацій\nмедичних',
    locations: 'міста, де\nдомовишься на візит',
  },
  visitSearch: {
    eVisit: [
      'Домовитись',
      {
        name: 'bold',
        data: 'е-візит',
      },
    ],
    stationaryVisit: [
      'Домовитись',
      {
        name: 'bold',
        data: 'стаціонарний візит',
      },
    ],
    completeDataPrompt: 'будь ласка, заповніть дані',
    selectSpecialtyOrDoctor: 'Вибрати спеціалізацію / лікаря',
    selectCity: 'Вибрати місто',
    selectDate: 'Видеріть дату',
    doctor: 'Лікарь',
    specialty: 'Спеціалізація',
    noSpecialties: 'Немає спеціалізації до вибору',
    noCities: 'Немає міст до вибору',
    requiredField: 'То поле є обов\'язковим.',
    visitType: {
      private: 'Приватний',
      nfz: 'НФЗ',
    },
    search: 'Пошук',
    doctorsAvailable: 'Лікарі онлайн',
    monthNames: [
      'Січень',
      'Лютий',
      'Березень',
      'Квітень',
      'Май',
      'Червень',
      'Липень',
      'Серпень',
      'Вересень',
      'Жовтень',
      'Листопад',
      'Грудень',
    ],
    dayNames: [
      'Понеділок',
      'Вівторок',
      'Середа',
      'Четвер',
      'П\'ятниця',
      'Субота',
      'Неділя',
    ],
  },
};
