import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Welcome",
    component: () => import(/* webpackChunkName: "Welcome" */ "@/views/Welcome"),
  },
  {
    // handling any other route, needs to be the last route!
    path: '/:notFound(.*)',
    name: 'NotFound',
    redirect: '/',
  },
];

export default routes;
