<template>
  <div class="app-notifications">
    <div class="app-notifications__container">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="notification"
        :class="{
          'notification--info': !item.type || item.type === 'info',
          'notification--success': item.type === 'success',
          'notification--error': item.type === 'error',
          'notification--warning': item.type === 'warning',
        }"
      >
        <div class="notification__image">
          <img
            v-if="!item.type || item.type === 'info'"
            src="~@/assets/icons/info-ico.svg"
            alt="asset"
          >
          <img
            v-if="item.type && item.type === 'success'"
            src="~@/assets/icons/success-ico.svg"
            alt="asset"
          >
          <img
            v-if="item.type && item.type === 'error'"
            src="~@/assets/icons/error-ico.svg"
            alt="asset"
          >
          <img
            v-if="item.type && item.type === 'warning'"
            src="~@/assets/icons/alert-ico.svg"
            alt="asset"
          >
        </div>
        <div class="notification__content">
          <h4 v-if="item.title">
            {{ item.title }}
          </h4>
          <div
            v-if="item.content"
            class="content"
            v-html="item.content"
          />
        </div>
        <div
          v-if="!item.preventClose"
          class="notification__controls"
        >
          <span @click="close(item._id)">&#10005;</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({

  name: 'Notifications',
  data(){
    return {
      items: [],
    };
  },
  beforeMount(){

    this.$emitter.on('push', (data = {}) => {
      this.push(data);
    });

    this.$emitter.on('closeAll', () => {
      this.clear();
    });

  },
  beforeUnmount(){
    this.clear();
  },
  methods: {

    generateRand(length){

      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;

    },

    push(data){

      const notification = data;

      notification._id = this.generateRand(10);
      this.items.push(notification);

      if (!notification.constant){

        setTimeout(() => {
          this.close(notification._id);
        }, notification.timeOut || 8000);

      }

    },

    clear(){
      this.items = [];
    },

    close(id){
      const notification = this.items.find(not => not._id === id);
      if (notification) this.items = this.items.filter(not => not._id !== id);
    },

  },

});

</script>

<style lang="scss" src="./notifications.scss" />
