export default {

  SET_USER(state, user) {
    state.user = user;
  },

  SET_FACILITY(state, facility) {
    state.facility = facility;
  },

  SET_FILES(state, files) {
    state.files = files;
  },

  SET_COMMENT(state, comment) {
    state.comment = comment;
  },

  SET_PROFILE(state, profile) {
    state.selectedProfile = profile;
  },

  SET_PROFILES(state, profiles) {
    state.profiles = profiles;
  },

  SET_ACCOUNTS(state, accounts) {
    state.remoteAccounts = accounts;
  },

  SET_PAYMENT_DATA(state, data) {
    state.paymentData = data;
  },

  SET_LOYALTY_DISCOUNT(state, discount) {
    state.loyaltyDiscount = discount;
  },

};
