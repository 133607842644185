export default {

  setRemoteAppData({ commit }, data) {
    commit('SET_REMOTE_APP_DATA', data);
  },

  setCity({ commit }, selectedCity){
    commit('SET_CITY', selectedCity);
  },

  setDoctor({ commit }, selectedDoctor){
    commit('SET_DOCTOR', selectedDoctor);
  },

  setSpeciality({ commit }, selectedSpeciality){
    commit('SET_SPECIALITY', selectedSpeciality);
  },

  setVisitDate({ commit }, date){
    commit('SET_VISIT_DATE', date);
  },

  setFreeTerms({ commit }, terms){
    commit('SET_FREE_TERMS', terms);
  },

  setSelectedTerm({ commit }, term){
    commit('SET_SELECTED_TERM', term);
  },

  setVisitType({ commit }, type){
    commit('SET_VISIT_TYPE', type);
  },

  setVisitKind({ commit }, kind){
    commit('SET_VISIT_KIND', kind);
  },

  setSelectedService({ commit }, data) {
    commit('SET_SELECTED_SERVICE', data);
  },

  setCities({ commit }, cities){
    commit('SET_CITIES', cities);
  },

  setDoctors({ commit }, doctors){
    commit('SET_DOCTORS', doctors);
  },

  setSpecialities({ commit }, specialities){
    commit('SET_SPECIALITIES', specialities);
  },

};
