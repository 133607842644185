import Vue from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';

const router = createRouter({
  history: createWebHistory('/welcome/'),
  routes,
  linkExactActiveClass: 'is-active',
});

router.beforeEach((to, _from, next) => {
  const token = localStorage.getItem('token');

  if (to.matched.some(route => route.meta.secured)) {
    if (!token) {
      next({ name: "Login" });
      return;
    }
    next();
    return;
  }
  next();
});

export default router;
