import { LocaleFlag } from "@/models";

// eslint-disable-next-line no-shadow
export enum LocaleOptions { 
  PL = 'pl',
  EN = 'en',
  UK = 'uk'
}

export const Locales: LocaleFlag[] = [
  {
    locale: LocaleOptions.PL,
    file: 'pl.svg',
  },
  {
    locale: LocaleOptions.EN,
    file: 'uk.svg',
  },
  {
    locale: LocaleOptions.UK,
    file: 'ua.svg',
  },
];
