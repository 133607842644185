export default {
    
  LOADER_ENQUEUE(state, loaderId) {
    state.loader = [...state.loader, loaderId];
  },

  LOADER_DEQUEUE(state, loaderId) {
    state.loader = state.loader.filter(item => item !== loaderId);
  },

  CLEAR_LOADER(state) {
    state.loader = [];
  },

};
