const root = `${process.env.VUE_APP_HOST}patient`;
const edm = `${process.env.VUE_APP_EDM_HOST}`;

export const ExternalLinks = {
  MYDR_LOGIN: `${root}`,
  MYDR_REGISTER: `${root}/register`,
  MYDR_NEW_VISIT: (type: 'evisit' | 'stationary') => `${root}/new-visit/${type}`,
  MYDR_EDM: edm,
  MYDR_EDM_LOGIN: `${edm}/secure`,
} as const;
