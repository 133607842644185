<template>
  <div id="app">
    <RouterView />
    <Loader v-if="loader.length" />
    <Notifications />
  </div>
</template>

<script type="ts">

import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import Loader from '@/components/layout/loader/loader.vue';
import Notifications from '@/components/layout/notifications/notifications.vue';

export default defineComponent({
  name: 'App',
  components: {
    Loader,
    Notifications,
  },
  data(){
    return {

      loadAttempt: 0,

    };
  },
  computed: {
    ...mapState('loader', ['loader']),
    ...mapState('global', [
      'cities',
      'doctors',
      'specialities',
    ]),
  },
  created(){
    this.initQuery();
  },
  methods: {

    ...mapActions('global', [
      'setCities',
      'setDoctors',
      'setSpecialities',
    ]),

    initQuery(){

      const load = this.app__setLoader();
      this.loadAttempt += 1;

      this.app__getToken('mydr-website-token').then(async() => {

        this.getLocations();
        // this.getSugestions('person');
        this.getSugestions('specialty');
        this.loadAttempt = 0;

      }).catch(async() => {

        if (this.loadAttempt >= 2) return;

        await this.getToken();
        this.initQuery();

      }).finally(() => {
        this.app__loaderDequeue(load);
      });

    },

    getToken(){

      return new Promise(resolve => {

        const formData = new FormData();
        formData.append("client_secret", process.env.VUE_APP_CLIENT_CREDENTIALS_SECRET);
        formData.append("client_id", "cc_frontend");
        formData.append("grant_type", "client_credentials");

        this.$http.post(process.env.VUE_APP_TOKEN, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          this.app__setLocalStorage('mydr-website-token', response.data, response.data.expires_in / 60 / 60);
        }).finally(() => {
          resolve();
        });

      });

    },

    getSugestions(type, query){

      return new Promise((resolve, reject) => {

        this.app__getToken('mydr-website-token').then(token => {

          this.$http.get('visits/search_autosuggest/', {
            headers: {
              Authorization: `${token.token_type} ${token.access_token}`,
              'Content-Type': 'Application/json',
            },
            params: {
              search_type: type,
              limit: 10,
              q: query,
            },
          }).then(response => {

            if (type === 'city') this.setCities(response.data);
            if (type === 'person') this.setDoctors(response.data);
            if (type === 'specialty') this.setSpecialities(response.data);

            resolve(response.data);

          }).catch(error => {
            reject(new Error(error));
          });

        });

      });

    },

    getLocations(q) {

      return new Promise((resolve, reject) => {

        this.app__getToken('mydr-website-token').then(token => {

          this.$http.get('location/search_location/', {
            headers: {
              Authorization: `${token.token_type} ${token.access_token}`,
              'Content-Type': 'Application/json',
            },
            params: {
              limit: 10,
              q,
            },
          }).then(res => {
            const cities = res.data.map(item => ({ name: item.place_name, state_name_pl: item.state_name_pl, state_name: item.state_name }));
            this.setCities(cities);
            resolve(cities);
          }).catch(error => {
            reject(error);
          });

        });

      });
    },
  },
});
</script>

<style lang="scss" src="@/assets/styles/index.scss" />
