import { createApp } from 'vue';
import App from './app';
import {
  emitter,
  http,
  translator,
} from './plugins';
import router from './router';
import store from './store';
import { mixins } from './mixins/global';

(async() => {
  const app = createApp(App);
  app
    .use(http)
    .use(emitter)
    .use(translator)
    .use(router)
    .use(mixins)
    .use(store)
    .mount('#app');
})();
