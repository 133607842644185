import { App, inject } from 'vue';
import mitt, { Emitter } from 'mitt';

type Events = {
  'notification-push': unknown;
  'notification-closeAll': unknown;
}

export const emitterSymbol = Symbol('emitter');

function install(Vue: App): void {
  const emitter = mitt();
  Vue.config.globalProperties.$emitter = emitter;  //eslint-disable-line
  Vue.provide(emitterSymbol, emitter);
}

function createEmitter(): {install: (T: App) => void} {
  return {
    install: (app: App) => install(app),
  };
}

export const emitter = createEmitter();

export function useEmitter() {
  const emitterInstance = inject<Emitter<Events>>(emitterSymbol);
  if (!emitterInstance) throw new Error('No emitter provided!');

  return emitterInstance;
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $emitter: Emitter<Events>;
  }
}
