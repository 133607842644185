export default {

  methods: {

    app__setLoader() {

      const loaderId = Math.random().toString(36).substring(5);
      this.$store.dispatch('loader/loaderEnqueue', loaderId);
      return loaderId;

    },

    app__clearLoader() {
      this.$store.dispatch('loader/clearLoader');
    },

    app__loaderEnqueue(lazyPromise) {

      return new Promise((resolve, reject) => {
        const loader = this.app__setLoader();
        lazyPromise().then(data => {
          resolve(data);
        }).catch(error => {
          reject(error);
        }).finally(() => {
          this.app__loaderDequeue(loader);
        });
      });

    },

    app__loaderDequeue(loaderId) {
      this.$store.dispatch('loader/loaderDequeue', loaderId);
    },

  },

};
