import { App } from 'vue';

import localStorage from './localStorage';
import token from './token';
import loader from './loader';
import mobile from './mobile';

// Global mixins registration
export const mixins = (Vue: App) => {
  Vue.mixin(localStorage);
  Vue.mixin(token);
  Vue.mixin(loader);
  Vue.mixin(mobile);
};
