export default {

  remoteAppData: null,

  cities: [],
  doctors: [],
  specialities: [],
    
  selectedCity: null,
  selectedDoctor: null,
  selectedSpeciality: null,
  visitDate: null,
  freeTerms: [],
  selectedTerm: null,
  visitType: null,
  visitKind: null,
  selectedService: null,
    
};
