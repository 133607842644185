export default {

  methods: {

    app__isMobile(){
      const width = document.documentElement.clientWidth;
      return width <= 600;
    },

  },

};
