import { Dictionary } from "./models";

export const pl: Dictionary = {
  about: {
    title:'Kim jesteśmy',
    content: 'MyDr to zespół pasjonatów nowych technologii, którzy od podstaw stworzyli i nadal rozwijają zaawansowane programy do prowadzenia Elektronicznej Dokumentacji Medycznej i kompleksowego zarządzania przychodniami. Firma wprowadziła polski system opieki zdrowotnej w XXI wiek i uczyniła go przyjaznym zarówno dla lekarza, jak i pacjenta.\n'
      + '</br></br>Flagowy produkt - MyDr EDM - znalazł zastosowanie w ponad 10 tysiącach przychodniach, prowadzących działalność komercyjną oraz w tych, mających kontrakt z NFZ. Program zupełnie za darmo oferuje pełen pakiet funkcjonalności niezbędnych w codziennej pracy każdej placówki: od zaawansowanego grafiku, poprzez narzędzia potrzebne w czasie przeprowadzania wizyty (moduły do e-recept, e-skierowań i e-zwolnień), po rozwiązania telemedyczne i kończąc na pełnej integracji z rządową Platformą P1.\n'
      + '</br></br>W portfolio MyDr znajduje się również program dr100 - stworzony od podstaw z myślą o placówkach stomatologicznych. Oprócz zestawu narzędzi do EDM i zarządzania czasem pracy, oferuje rozwiązania stricte dedykowane stomatologom, takie jak interaktywny diagram zębowy, karta periodontologiczna, moduły protetyczny czy ortodontyczny.\n'
      + '</br></br>Firma MyDr została założona w 2017 roku i od tego czasu z powodzeniem rozwija się i utrzymuje status lidera w pełni chmurowych rozwiązań EDM rynku oprogramowania medycznego w Polsce.',
  },
  benefits: {
    benefits: {
      prescription: 'Przedłuż e-receptę',
      makeAppointment: 'Umów e-wizytę prywatną lub na NFZ',
      laboratoryTests: 'Sprawdź wyniki swoich badań laboratoryjnych',
      medicalHistory: 'Przeglądaj swoją historię medyczną',
    },
    makeAppointment: 'Umów wizytę',
  },
  footer: {
    nav: {
      doctors: {
        title: 'dla lekarzy',
        drw: 'Bezpłatny program\ndo prowadzenia placówki medycznej',
      },
      etc: 'zobacz także',
    },
  },
  header: {
    signin: 'Logowanie',
    signinMenu: {
      patient: 'Pacjent',
      clinic: 'Przychodnia',
    },
    nav: {
      howItWorks: 'jak to działa',
      specialists: 'specjaliści',
      mobileApp: 'aplikacja mobilna',
      aboutUs: 'o nas',
      clinicZone: 'strefa przychodni',
    },
    makeAppointment: 'Umów wizytę',
  },
  howItWorks: {
    title: 'Jak to działa',
    description: 'Mydr to serwis łączący w sobie wyszukiwarkę lekarzy z pełnym dostępem do Twoich danych medycznych.\nInternetowe konto pacjenta, dzięki któremu masz dostęp do wszystkich swoich danych medycznych.',
    recap: 'Umów wizytę, otrzymaj e-receptę lub e-zwolnienie bez wychodzenia z domu.',
  },
  mobile: {
    title: [
      {
        name: 'bold',
        data: 'Pobierz aplikację',
      },
      'i zaloguj się do swojego zdrowia',
    ],
    android: [
      {
        name: 'bold',
        data: 'bezpłatna',
      },
      'aplikacja\nna urządzenia z systemem Android',
    ],
    ios: [
      {
        name: 'bold',
        data: 'bezpłatna',
      },
      'aplikacja\nna urządzenia z systemem iOS',
    ],
  },
  offer: {
    title: 'Jesteś w dobrych rękach',
    safety: 'Gwarancja bezpieczeństwa\n Twoich danych',
    cancelation: 'Możliwość bezpłatnego odwołania wizyty lub zmiany jej terminu',
    availability: 'Dostęp do usługi\n  całą dobę 7 dni w tygodniu',
  },
  specialists: {
    title: [
      'Najlepsi specjaliści',
      {
        name: 'bold',
        data: 'do Twojej dyspozycji',
      },
    ],
    signup: 'zarejestruj się',
    doctors: 'lekarzy różnych\nspecjalizacji',
    specialties: 'specjalizacji\nlekarskich',
    locations: 'miejscowości, w których\numówisz się na wizytę',
  },
  visitSearch: {
    eVisit: [
      'umów',
      {
        name: 'bold',
        data: 'e-wizytę',
      },
    ],
    stationaryVisit: [
      'umów',
      {
        name: 'bold',
        data: 'wizytę stacjonarną',
      },
    ],
    completeDataPrompt: 'proszę uzupełnić dane',
    selectSpecialtyOrDoctor: 'Wybierz specjalizację / lekarza',
    selectCity: 'Wybierz miasto',
    selectDate: 'Wybierz datę',
    doctor: 'Lekarz',
    specialty: 'Specjalizacja',
    noSpecialties: 'Brak specjalizacji do wyboru',
    noCities: 'Brak miast do wyboru',
    requiredField: 'To pole jest wymagane.',
    visitType: {
      private: 'Prywatna',
      nfz: 'NFZ',
    },
    search: 'sprawdź terminy',
    doctorsAvailable: 'Lekarzy on-line',
    monthNames: [
      'Styczeń',
      'Luty',
      'Marzec',
      'Kwiecień',
      'Maj',
      'Czerwiec',
      'Lipiec',
      'Sierpień',
      'Wrzesień',
      'Październik',
      'Listopad',
      'Grudzień',
    ],
    dayNames: [
      'Poniedziałek',
      'Wtorek',
      'Środa',
      'Czwartek',
      'Piątek',
      'Sobota',
      'Niedziela',
    ],
  },
};
