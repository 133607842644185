import { Dictionary } from "./models";

export const en: Dictionary = {
  about: {
    title:'Who are we',
    content: 'We are the leader on the medical information market in Poland. For over a dozen years we have been providing doctors, pharmacists and patients with comprehensive data on all medicinal products available on the Polish market. We also keep you informed about the latest developments in the healthcare sector and interesting solutions used in medicine. Our offer also includes modern products in the field of new technologies, such as medical mobile applications or a program for running a medical clinic.',
  },
  benefits: {
    benefits: {
      prescription: 'Extend your e-prescription',
      makeAppointment: 'Make an online appointment',
      laboratoryTests: 'Check your laboratory tests results',
      medicalHistory: 'View your medical history',
    },
    makeAppointment: 'Make an appointment',
  },
  footer: {
    nav: {
      doctors: {
        title: 'for doctors',
        drw: 'Free program\nfor running a medical facility',
      },
      etc: 'see also',
    },
  },
  header: {
    signin: 'Sign in',
    signinMenu: {
      patient: 'Patient',
      clinic: 'Clinic',
    },
    nav: {
      howItWorks: 'how it works',
      specialists: 'specialists',
      mobileApp: 'mobile app',
      aboutUs: 'about us',
      clinicZone: 'clinic zone',
    },
    makeAppointment: 'schedule a visit',
  },
  howItWorks: {
    title: 'How it works',
    description: 'Mydr is a service that combines a doctor\'s search engine with full access to your medical data.\nAn online patient account, by which you have access to all your medical data.',
    recap: 'Make an appointment, get an e-prescription or medical leave without leaving your home.',
  },
  mobile: {
    title: [
      {
        name: 'bold',
        data: 'Download the app',
      },
      'and sign in to your health',
    ],
    android: [
      {
        name: 'bold',
        data: 'free',
      },
      'application\nfor Android devices',
    ],
    ios: [
      {
        name: 'bold',
        data: 'free',
      },
      'application\nfor iOS devices',
    ],
  },
  offer: {
    title: 'You are in good hands',
    safety: 'Guarantee of the security\n of your data ',
    cancelation: 'Visit cancelation and rescheduling\n free of charge',
    availability: 'Service available\n 24 hours a day, 7 days a week',
  },
  specialists: {
    title: [
      'The best specialists',
      {
        name: 'bold',
        data: 'at your service',
      },
    ],
    signup: 'register',
    doctors: 'doctors of various\nspecializations ',
    specialties: 'medical\nspecializations ',
    locations: 'locations where you can make\nan appointment',
  },
  visitSearch: {
    eVisit: [
      'schedule',
      {
        name: 'bold',
        data: 'an e-visit',
      },
    ],
    stationaryVisit: [
      'schedule',
      {
        name: 'bold',
        data: 'a stationary visit',
      },
    ],
    completeDataPrompt: 'please fill in the data',
    selectSpecialtyOrDoctor: 'Select specialty / doctor',
    selectCity: 'Select city',
    selectDate: 'Select date',
    doctor: 'Doctor',
    specialty: 'Specialty',
    noSpecialties:'No specialties to choose from',
    noCities: 'No cities to choose from',
    requiredField: 'This field is required',
    visitType: {
      private: 'Private',
      nfz: 'NFZ',
    },
    search: 'search for visits',
    doctorsAvailable: 'Doctors available on-line',
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    dayNames: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
  },
};
